import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import MentionsLegales from '../views/MentionsLegales.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import MaintenancePage from '@/views/MaintenancePage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/m',
      name: 'Maintenance',
      component: MaintenancePage
    },
    {
      path: '*',
      redirect: '/m'
    },
    {
      path: '/notre-histoire',
      name: 'notre-histoire',
      component: AboutView
    },
    {
      path: '/mentions-legales',
      name: 'mentions-legales',
      component: MentionsLegales
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFoundComponent
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/',
      name: 'home',
      component: HomeView
    }
  ]
});


// Ce code génère un fichier sitemap.xml avec une liste d'URL. Copier et coller ensuite le contenu du sitemap généré dans un fichier sitemap.xml dans votre dossier public

const builder = require('xmlbuilder');
const routes = ['/','/notre-histoire','/mentions-legales'];

let xml = builder.create('urlset', { version: '1.0', encoding: 'UTF-8' })
  .att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9');

routes.forEach(route => {
  xml = xml.ele('url')
    .ele('loc', `https://christianmanzala.com${route}`)
    .up();
});

const sitemap = xml.end({ pretty: true });

console.log(sitemap);