<template>
  <v-app id="home">
    <div class="page-wrapper">
      <!-- Section 1 : Logo avec marquee ceinture -->
      <div class="section section-1 d-flex align-center justify-center">
        <v-img
          src="@/assets/Christian Manzala Primary logo Black.png"
          alt="Logo"
          max-width="270"
          class="logo"
        />
      </div>

      <!-- Section 2 : Catégorie 1 -->
      <div class="section section-2 d-flex align-center justify-center">
        <div class="content">
          <h1>Catégorie 1</h1>
          <p>Contenu de la première catégorie ici.</p>
        </div>
      </div>

      <!-- Section 3 : Catégorie 2 -->
      <div class="section section-3 d-flex align-center justify-center">
        <div class="content">
          <div>
            <h2 class="text-center pb-10">Ils nous font confiance</h2>
          </div>

          <div class="d-flex flex-wrap justify-space-around"> 
             <v-img contain max-width="100" src="../assets/Paris_92.svg"></v-img>
          </div>
        </div>
      </div>

      <v-row id="contact" class="pt-5 pb-5">
        <v-col style="text-align: center">
          <div class="child">
            <h1>Contact</h1>
            <br>
            <h2>Informations</h2>
            <span class="text-caption">contact@christianmanzala.eu</span><br><br/>
            <h2>Création sur mesure</h2>
            <span class="text-caption">showroom@christianmanzala.eu</span><br><br/>
            <h2>Service après vente</h2>
            <span class="text-caption">sav@christianmanzala.eu</span>
          </div>
        </v-col>
        
        <v-col cols="12" sm="8">
          <form name="form" ref="form" @submit.prevent="sendEmail">
            <select id="emails" name="subject">
              <option value="" disabled selected>Sélectionnez le sujet ▼</option>
              <option value="contact@christianmanzala.eu">Informations</option>
              <option value="showroom@christianmanzala.eu">Création sur mesure</option>
              <option value="sav@christianmanzala.eu">Service après vente</option>
            </select>
            
            <input type="text" name="user_name" placeholder="Votre nom">
            
            <input type="email" name="user_email" placeholder="Votre adresse email">

            <input type="tel" name="user_phone" placeholder="Votre numéro de téléphone">
            
            <textarea name="message" cols="30" rows="5" placeholder="Message..."></textarea>
            
            <input type="submit" value="Envoyer" class="hover-submit">
          </form>
        </v-col>
      </v-row>

      <FooterView />
    </div>
  </v-app>
</template>

<script>
  import FooterView from '@/components/FooterView.vue';
  import emailjs from '@emailjs/browser';

  export default {
    name: 'HomeView',

    metaInfo: {
      title: 'Christian Manzala | Site officiel',
      meta: [
        { name: 'description', content: 'Découvrez le site officiel de Christian Manzala. Explorez nos créations originales, notre vision artistique et notre parcours professionnel.' },
        { property: 'og:title', content: 'Christian Manzala | Site officiel' }
      ]
    },

    components: {
      FooterView,
    },

    created() {
      window.scrollTo(0,0);
    },

    data () {
      return {
        loader: "bars",
        dialog_success: false,
        dialog_failure: false,
        dialog_failure_api: false,
        isLoading: true
      }
    },

    methods: {
      sendEmail() {
        var vm = this;
        var name = document.forms["form"]["user_name"].value;
        var email = document.forms["form"]["user_email"].value;
        var phone = document.forms["form"]["user_phone"].value;
        var message = document.forms["form"]["message"].value;
        var subject = document.forms["form"]["subject"].value;

        function isEmptyOrSpaces(str){
            return str === null || str.match(/^ *$/) !== null;
        }

        if (isEmptyOrSpaces(name)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(email)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(phone)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(message)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(subject)) {
          vm.dialog_failure = true;
        }

        else if (subject == 'contact@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_3lps2jd', 'template_rlvb4lr', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });
          
          
        }

        else if (subject == 'showroom@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_ry5avdw', 'template_8foimg9', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });

          
        }

        else if (subject == 'sav@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_ry5avdw', 'template_8foimg9', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });
        }
      },

      reloadPage() {
        window.location.reload();
      },
    },

    mounted() {
      window.addEventListener('load', () => {
        this.isLoading = false;
      });
    },
  }
</script>

<style scoped>
body {
  color: black;
}

.page-wrapper {
  scroll-snap-type: y mandatory; /* Défilement par section */
  height: 100vh;
  width: 100vw;
}

.section {
  min-height: 100vh;
  width: 100%;
  scroll-snap-align: start; /* Chaque section s'aligne au début */
  position: relative;
}

.section-1 {
  background: #FDFCFA;
}

.section-2 {
  background: #FDFCFA;
}

.section-3 {
  background: #FDFCFA;
}

#contact {
  background: #FDFCFA;
}

.logo {
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.content {
  text-align: center;
}

.child {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
}

select {
  width: 80%;
  padding: 12px 20px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  cursor: pointer;
}

input[type=text], [type=email], textarea {
  width: 80%;
  padding: 12px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=tel] {
  width: 80%;
  padding: 12px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hover-submit {
  transition: 0.2s;
}

.hover-submit:hover {
  background-color: goldenrod;
  color: black;
  transition: 0.3s;
}

.hover:hover {
  cursor: pointer;
}
</style>