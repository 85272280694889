<template>
    <v-footer padless class="black--text">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-divider class="black" />
            <v-card-text class="text-center pt-4">
              <h2>Suivez-nous</h2>
            </v-card-text>
            <v-card-text class="text-center">
              <v-btn
                v-for="icon in socialIcons"
                :key="icon.icon"
                :href="icon.link"
                target="_blank"
                icon
                class="mx-4 black--text"
              >
                <v-icon size="24">{{ icon.icon }}</v-icon>
              </v-btn>
            </v-card-text>
            <v-divider class="black" />
          </v-col>
  
          <v-col cols="12" class="text-center py-2">
            <v-btn text to="/notre-histoire" class="black--text mx-2">Notre histoire</v-btn>
            <v-btn text href="#customer" class="black--text mx-2">Ils nous font confiance</v-btn>
            <v-btn text href="#contact" class="black--text mx-2">Contact</v-btn>
            <v-btn text to="/mentions-legales" class="black--text mx-2">Mentions Légales</v-btn>
          </v-col>
  
          <v-col cols="12">
            <v-divider class="black" />
            <v-card-text class="text-center">
              {{ currentYear }} — <strong>Christian Manzala. Tous droits réservés.</strong>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </template>
  
  <script>
  export default {
    name: 'FooterView',
    data() {
      return {
        socialIcons: [
          { icon: 'mdi-youtube', link: 'https://www.youtube.com/@christianmanzala' },
          { icon: 'mdi-facebook', link: 'https://www.facebook.com/profile.php?id=100088888763182' },
          { icon: 'mdi-twitter', link: '' },
          { icon: 'mdi-linkedin', link: 'https://fr.linkedin.com/in/christian-manzala-179383149' },
          { icon: 'mdi-instagram', link: 'https://www.instagram.com/christianmanzalaofficial/' }
        ]
      };
    },
    computed: {
      currentYear() {
        return new Date().getFullYear();
      }
    }
  };
  </script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
  }

  .v-footer {
    background: #FDFCFA;
  }
  </style>