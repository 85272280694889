import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Loading from 'vue-loading-overlay'
import VueMeta from 'vue-meta'

const maintenanceMode = true;

router.beforeEach((to, from, next) => {
  if (maintenanceMode && to.path !== '/m') {
    next('/m');
  } else {
    next();
  }
});

Vue.use(VueMeta)

library.add(faHatWizard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// define the plugin and pass object for config
Vue.use(Loading, {
  color: 'black',
  width: 64,
  height: 64,
  backgroundColor: '#ffffff',
  opacity: 0.5,
  zIndex: 9999,
  lockScroll: true
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');